<template>
  <li :class="{ active: isActive }" class="monitoring-tab-item" @click="nav">
    <fa-icon class="nav-icon" :icon="icon" />
    <a class="nav-link">{{ text }}</a>
  </li>
</template>

<script>
export default {
  name: "MonitoringTabButton",
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    route: { type: String, default: '#' },
  },
  computed: {
    isActive() {
      return this.$route.name === this.route
    },
  },
  methods: {
    nav() {
      this.$router.push({ name: this.route })
    },
  },
}
</script>

<style lang="scss" scoped>
  .monitoring-tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;

    .nav-link {
      user-select: none;
      font-size: 1em;
      padding: 0.5em;
    }

    .nav-icon {
      padding: 0.5em;
      padding-right: 0;
    }
  }
  .active {
    // border-bottom: solid 2px #0d1469;
    background-color: #0d1469;
    color: white;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
  }

</style>