<template>
  <b-container class="p-0 m-0">
    <ul class="tab-bar">
      <monitoring-tab-button text="Overview" route="monitoring-overview" icon="home" />
      <monitoring-tab-button text="Reports" route="monitoring-reports" icon="newspaper" />
      <monitoring-tab-button text="Keywords" route="monitoring-keywords" icon="key" />
      <monitoring-tab-button text="Trademarks" route="monitoring-trademarks" icon="trademark" />
      <monitoring-tab-button text="Companies" route="monitoring-companies" icon="briefcase" />
      <monitoring-tab-button text="Domains" route="monitoring-domains" icon="globe" />
      <monitoring-tab-button text="Manage" route="monitoring-manage" icon="cog" />
    </ul>
  </b-container>
</template>

<script>
import MonitoringTabButton from '@/components/Monitoring/MonitoringTabButton.vue'
export default {
  name: 'MonitoringTabBar',
  components: {
    MonitoringTabButton,
  },
}
</script>

<style lang="scss" scoped>
.tab-bar {
  border-bottom: solid 0.1em #0d1469;
  display: flex;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  margin-top: 4em;
}
</style>