var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", { staticClass: "p-0 m-0" }, [
    _c(
      "ul",
      { staticClass: "tab-bar" },
      [
        _c("monitoring-tab-button", {
          attrs: {
            text: "Overview",
            route: "monitoring-overview",
            icon: "home",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: {
            text: "Reports",
            route: "monitoring-reports",
            icon: "newspaper",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: {
            text: "Keywords",
            route: "monitoring-keywords",
            icon: "key",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: {
            text: "Trademarks",
            route: "monitoring-trademarks",
            icon: "trademark",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: {
            text: "Companies",
            route: "monitoring-companies",
            icon: "briefcase",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: {
            text: "Domains",
            route: "monitoring-domains",
            icon: "globe",
          },
        }),
        _c("monitoring-tab-button", {
          attrs: { text: "Manage", route: "monitoring-manage", icon: "cog" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }